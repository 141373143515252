<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="mb-4 max-w-full">
          <b-card-header>
            <h2>Accéder directement à un pays</h2>
          </b-card-header>
          <div class="d-flex justify-content-between">
            <a
              v-for="(country, key) of countries"
              :key="key"
              :href="`#${country}`"
            >
              <img
                style="width: 30px; margin-right: 10px"
                :src="require(`@/assets/images/flags/${country}.svg`)"
              >
              <!-- {{ country.charAt(0).toUpperCase() + country.slice(1) }} -->
            </a>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col id="europe" cols="12">
        <b-card>
          <b-card-header>
            <h2>
              Europe
              <img
                style="width: 30px; margin-left: 10px"
                :src="require(`@/assets/images/flags/france.svg`)"
              >
            </h2>
          </b-card-header>
          <iframe
            style="width: 100%; height: 80vh; position: relative"
            src="https://datastudio.google.com/embed/reporting/bf0c4c7f-e5ee-4b28-a91a-74681eef66c0/page/jvFmB"
            frameborder="0"
            allowfullscreen
          />
        </b-card>
      </b-col>
      <b-col id="france" cols="12">
        <b-card>
          <b-card-header>
            <h2>
              France
              <img
                style="width: 30px; margin-left: 10px"
                :src="require(`@/assets/images/flags/france.svg`)"
              >
            </h2>
          </b-card-header>
          <iframe
            style="width: 100%; height: 80vh; position: relative"
            src="https://datastudio.google.com/embed/reporting/677471db-1959-436f-9bca-436da8284c4f/page/jvFmB"
            frameborder="0"
            allowfullscreen
          />
        </b-card>
      </b-col>
      <b-col id="allemagne" cols="12">
        <b-card>
          <b-card-header>
            <h2>
              Allemagne
              <img
                style="width: 30px; margin-left: 10px"
                :src="require(`@/assets/images/flags/allemagne.svg`)"
              >
            </h2>
          </b-card-header>
          <iframe
            style="width: 100%; height: 80vh; position: relative"
            src="https://datastudio.google.com/embed/reporting/c2fdb631-09eb-43b2-98c2-d7da36513150/page/jvFmB"
            frameborder="0"
            allowfullscreen
          />
        </b-card>
      </b-col>
      <b-col id="espagne" cols="12">
        <b-card>
          <b-card-header>
            <h2>
              Espagne
              <img
                style="width: 30px; margin-left: 10px"
                :src="require(`@/assets/images/flags/allemagne.svg`)"
              >
            </h2>
          </b-card-header>
          <iframe
            style="width: 100%; height: 80vh; position: relative"
            src="https://datastudio.google.com/embed/reporting/e8006d56-11ca-4d05-9bbd-91cd0d9faf61/page/jvFmB"
            frameborder="0"
            allowfullscreen
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardHeader } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,

  },
  data() {
    return {
      countries: ['france', 'allemagne', 'italie', 'espagne', 'portugal'],
    }
  },
}
</script>
